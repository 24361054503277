/**
 * The below file is the Erosion Inmates Registration service which contains method for doing the API calls.
 * @author: vishal.mishra@velsof.com
 * @date : 08-03-2024
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Erosion_Create_Inmates_Registration,
  Erosion_Export_Inmates_Registration,
  Erosion_Import_Inmates_Registration,
  Multipart_Form_Data,
  Blob,
  Erosion_Export_Template_Inmates_Registration,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";

const ErosionInmatesRegistrationServices = {};

//#region Method for Erosion Freeze Report listing page.

/**
 * Method for getting the Erosion Freeze Report listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.getAll = function (param) {
  return fetch({
    url: Erosion_Create_Inmates_Registration,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

//#region Method for Erosion Freeze Report .

/**
 * Method for importing the Erosion Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.create = function (data) {
  return fetch({
    url: Erosion_Create_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Erosion Freeze Report .

/**
 * Method for importing the Erosion Freeze Report
 * @param {any} data API Data
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.update = function (data, id) {
  return fetch({
    url: Erosion_Create_Inmates_Registration + `/${id}`,
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for Erosion Freeze Report .

/**
 * Method for importing the Camp Inmates Registration
 * @param {any} data API data
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.campInmatesRegistrationImport = function (
  data
) {
  return fetch({
    url: Erosion_Import_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for getting the reliefCamp export data
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.getExportInmatesRegistrationData = function (
  payload
) {
  return fetch({
    url: Erosion_Export_Inmates_Registration,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
    responseType: Blob,
  });
};

/**
 * Method for getting the Template Import
 * @returns Fetch Method
 */
ErosionInmatesRegistrationServices.getImportTemplateInmatesRegistrationData =
  function (payload) {
    return fetch({
      url: Erosion_Export_Template_Inmates_Registration,
      method: "post",
      headers: {
        "Content-Type": Application_Json,
        Authorization: format(
          Authorization_Token,
          localStorage.getItem(AUTH_TOKEN)
        ),
      },
      data: payload,
      responseType: Blob,
    });
  };

export default ErosionInmatesRegistrationServices;
