/**
 * The below file contains the Sagas method for Erosion Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date : 07-03-2024
 */

import { Error, Success } from "constants/ApiConstant";
import { Erosion_Camp_Inmates_Imported } from "constants/MessageConstant";
import {
  all,
  takeEvery,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import {
  erosionSetAllCreateInmatesRegistration,
  erosionSetAllInmatesRegistration,
  erosionSetAllUpdateInmatesRegistration,
  erosionSetExportInmatesRegistration,
  erosionSetImportTemplateInmatesRegistration,
} from "redux/actions";

import { showMessage } from "redux/actions/Common";
import {
  GET_ALL_EROSION_INMATES_REGISTRATION,
  GET_ALL_CREATE_EROSION_INMATES_REGISTRATION,
  GET_ALL_UPDATE_EROSION_INMATES_REGISTRATION,
  CAMP_INMATES_REGISTRATION_IMPORT,
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/ErosionInmatesRegistration";
import ErosionInmatesRegistrationServices from "services/ErosionInmatesRegistrationServices";

export function* getAllInmatesRegistration() {
  yield takeEvery(
    GET_ALL_EROSION_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          ErosionInmatesRegistrationServices.getAll,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(erosionSetAllInmatesRegistration(inmatesData));
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for creating a new Freeze Unfreeze report
export function* getAllCreateInmatesRegistration() {
  yield takeEvery(
    GET_ALL_CREATE_EROSION_INMATES_REGISTRATION,
    function* ({ payload }) {
      try {
        const inmatesData = yield call(
          ErosionInmatesRegistrationServices.create,
          payload
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(erosionSetAllCreateInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: "Status of Registration Changed",
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(
          showMessage({
            message: error.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  );
}

// Saga for updating an existing Freeze Unfreeze report
export function* getAllUpdateInmatesRegistration() {
  yield takeEvery(
    GET_ALL_UPDATE_EROSION_INMATES_REGISTRATION,
    function* ({ payload, id }) {
      try {
        const inmatesData = yield call(
          ErosionInmatesRegistrationServices.update,
          payload,
          id
        );
        if (inmatesData.error != null && inmatesData.error.message) {
          yield put(
            showMessage({ message: inmatesData.error.message, type: Error })
          );
        } else {
          yield put(erosionSetAllUpdateInmatesRegistration(inmatesData));
          yield put(
            showMessage({
              message: "Status of Registration Changed",
              type: Success,
            })
          );
        }
      } catch (error) {
        yield put(showMessage({ message: error.message, type: Error }));
      }
    }
  );
}

//#endregion Method for listing

/**
 * Method for importing the reliefCamp
 */
export function* campInmatesRegistrationImport() {
  yield takeEvery(CAMP_INMATES_REGISTRATION_IMPORT, function* ({ payload }) {
    try {
      const reliefCamp = yield call(
        ErosionInmatesRegistrationServices.campInmatesRegistrationImport,
        payload
      );
      if (reliefCamp.error != null && reliefCamp.error.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Camp_Inmates_Imported, type: Success })
        );
      }
    } catch (err) {
      try {
        yield put(
          showMessage({
            message: JSON.parse(err.response.data.error.message),
            type: Error,
          })
        );
      } catch (error) {
        yield put(
          showMessage({
            message: err.response.data.error.message,
            type: Error,
          })
        );
      }
    }
  });
}

/**
 * Method for getting the reliefCamp export details
 */
export function* getReliefCampExport() {
  yield takeEvery(GET_EXPORT_CAMP_INMATES_DATA, function* ({ payload }) {
    try {
      const filter = {};
      filter.district = payload["filters[block][district][id][$eq]"];
      filter.block = payload["filters[block][id][$eq]"];
      filter.startDate = payload["filters[date][$gte]"];
      filter.endDate = payload["filters[date][$lte]"];

      const reliefCamp = yield call(
        ErosionInmatesRegistrationServices.getExportInmatesRegistrationData,
        filter
      );
      if (reliefCamp?.error != null && reliefCamp?.error?.message) {
        yield put(
          showMessage({ message: reliefCamp.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(reliefCamp),
        };
        yield put(erosionSetExportInmatesRegistration(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: err?.response?.data?.error?.message,
          type: Error,
        })
      );
    }
  });
}

/**
 * Method for getting the Registration Import details
 */
export function* getImportTemplateCampExport() {
  yield takeEvery(
    GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
    function* ({ payload }) {
      try {
        const filter = {};
        filter.district = payload.district;
        filter.block = payload.block;

        const reliefCamp = yield call(
          ErosionInmatesRegistrationServices.getImportTemplateInmatesRegistrationData,
          filter
        );
        if (reliefCamp?.error != null && reliefCamp?.error?.message) {
          yield put(
            showMessage({ message: reliefCamp.error.message, type: Error })
          );
        } else {
          let exportData = {
            url: window.URL.createObjectURL(reliefCamp),
          };
          yield put(erosionSetImportTemplateInmatesRegistration(exportData));
        }
      } catch (err) {
        yield put(
          showMessage({
            message: err?.response?.data?.error?.message,
            type: Error,
          })
        );
      }
    }
  );
}

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllInmatesRegistration),
    fork(getAllCreateInmatesRegistration),
    fork(getAllUpdateInmatesRegistration),
    fork(campInmatesRegistrationImport),
    fork(getReliefCampExport),
    fork(getImportTemplateCampExport),
  ]);
}
