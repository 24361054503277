/**
 * The below file contains the constants used in the Erosion Non Inmates Registration
 * @author: vishal.mishra@velsof.com
 * @date :22-01-2025
 */

export const GET_ALL_EROSION_NON_INMATES_REGISTRATION =
  "GET_ALL_EROSION_NON_INMATES_REGISTRATION";
export const SET_ALL_EROSION_NON_INMATES_REGISTRATION =
  "SET_ALL_EROSION_NON_INMATES_REGISTRATION";

export const REPORT_DATE_PARAM = "reportDate";
export const STATUS_PARAM = "status";
export const GET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION =
  "GET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION";
export const SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION =
  "SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION";

export const GET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION =
  "GET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION";
export const SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION =
  "SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION";

export const SET_EROSION_NON_INMATES_REGISTRATION = "SET_EROSION_NON_INMATES_REGISTRATION";

/**Constants for relief camp Import page */
export const NON_CAMP_INMATES_REGISTRATION_IMPORT =
  "EROSION_NON_CAMP_INMATES_REGISTRATION_IMPORT";
/**Constants for relief camp Import page */

/**Constants for camp inmates registration export */
export const GET_EXPORT_NON_CAMP_INMATES_DATA = "EROSION_GET_EXPORT_NON_CAMP_INMATES_DATA";
export const SET_EXPORT_NON_CAMP_INMATES_DATA = "EROSION_SET_EXPORT_NON_CAMP_INMATES_DATA";