import { all } from "redux-saga/effects";
import Auth from "./Auth";
import District from "./District";
import Cwc from "./Cwc";
import Block from "./Block";
import Village from "./Village";
import ReliefCamp from "./ReliefCamp";
import InfDepartment from "./InfDepartment";
import Disaster from "./Disaster";
import InfDamage from "./InfDamage";
import InfOtherDamage from "./InfOtherDamage";
import Erosion from "./Erosion";
import River from "./River";
import Agency from "./Agency";
import RescueEquipment from "./RescueEquipment";
import ReliefOtherItem from "./ReliefOtherItem";
import Unit from "./Unit";
import UrbanFlood from "./UrbanFlood";
import Flood from "./Flood";
import Wards from "./Wards";
import Landslide from "./Landslide";
import Storm from "./Storm";
import Lightning from "./Lightning";
import Earthquake from "./Earthquake";
import User from "./User";
import Notification from "./Notification";
import Fire from "./Fire";
import FireCategory from "./FireCategory";
import FireCause from "./FireCause";
import FireClass from "./FireClass";
import SpecialCallType from "./SpecialCallType";
import OriginArea from "./OriginArea";
import FireDetection from "./FireDetection";
import FireAgency from "./FireAgency";
import TypesOfOccupancy from "./TypesOfOccupancy";
import VehicleCategory from "./VehicleCategory";
import Station from "./Station";
import LivestockTypes from "./LivestockTypes";
import Role from "./Role";
import ApacheSuperset from "./ApacheSuperset";
import ResourceGroup from "./ResourceGroup";
import ResourceDocument from "./ResourceDocument";
import { getAllFloodDeathCause } from "./FloodDeathCause";
import FloodFreezeReport from "./FloodFreezeReport";
import FloodInmatesRegistration from "./FloodInmatesRegistration";
import FloodNonInmatesRegistration from "./FloodNonInmatesRegistration";
import ErosionInmatesRegistration from "./ErosionInmatesRegistration";
import ErosionNonInmatesRegistration from "./ErosionNonInmatesRegistration";

/**
 * Method for storing the multiple Sagas
 * @param {any} getState State object
 */
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    District(),
    Cwc(),
    Block(),
    Village(),
    ReliefCamp(),
    InfDepartment(),
    Disaster(),
    InfDamage(),
    InfOtherDamage(),
    Erosion(),
    River(),
    Agency(),
    RescueEquipment(),
    ReliefOtherItem(),
    Unit(),
    UrbanFlood(),
    Flood(),
    Wards(),
    Landslide(),
    Storm(),
    Lightning(),
    Earthquake(),
    User(),
    Notification(),
    Fire(),
    FireCause(),
    FireCategory(),
    SpecialCallType(),
    FireClass(),
    OriginArea(),
    FireDetection(),
    FireAgency(),
    TypesOfOccupancy(),
    VehicleCategory(),
    Station(),
    LivestockTypes(),
    Role(),
    ApacheSuperset(),
    ResourceGroup(),
    ResourceDocument(),
    getAllFloodDeathCause(),
    FloodFreezeReport(),
    FloodInmatesRegistration(),
    FloodNonInmatesRegistration(),
    ErosionInmatesRegistration(),
    ErosionNonInmatesRegistration(),
  ]);
}
