/**
 * The below JS file contains all the messages used.
 * @author: neeraj.singh.rawat@velsof.com
 * @Date : 14-10-2022
 */

/**Constants for showing the message for District */
export const District_Added = "District Added Successfully";
export const District_Updated = "District Updated Successfully";
export const District_Imported = "District Imported Successfully";

/**Constants for showing the message for CWC */
export const Cwc_Added = "CWC Data Added Successfully";
export const Cwc_Updated = "CWC Data Updated Successfully";
export const Cwc_Imported = "CWC Data Imported Successfully";

/**Constants for showing the message for Block */
export const Block_Added = "Revenue Circle Added Successfully";
export const Block_Updated = "Revenue Circle Updated Successfully";
export const Block_Imported = "Revenue Circle Imported Successfully";

/**Constants for showing the message for Village */
export const Village_Added = "Village Added Successfully";
export const Village_Updated = "Village Updated Successfully";
export const Village_Imported = "Village Imported Successfully";

/**Constants for showing the message for Relief Camp */
export const ReliefCamp_Added = "Relief Camp Added Successfully";
export const ReliefCamp_Updated = "Relief Camp Updated Successfully";
export const ReliefCamp_Imported = "Relief Camp Imported Successfully";

/**Constants for showing the message for Infrastructure Department */
export const InfDepartment_Added =
  "Infrastructure Department Added Successfully";
export const InfDepartment_Updated =
  "Infrastructure Department Updated Successfully";
export const InfDepartment_Imported =
  "Infrastructure Department Imported Successfully";

/**Constants for showing the message for File */
export const File_Size = "File size must be less than and equal to 5MB.";
export const File_Import_Extension = ".csv extension file are allowed.";

//#region Infrastructure Damage Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-11-2022

/**Constants for showing the message for InfDamage */
export const InfDamage_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const InfDamage_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const InfDamage_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const InfDamage_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const InfDamage_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const InfDamage_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const InfDamage_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const InfDamage_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const InfDamage_Imported = "Infrastructure Damage Imported Successfully";
export const InfDamage_Damage_Number =
  "Add Details should be less than and equal to {0}. Please remove extra details";

export const InfDamage_Report_Submit =
  "Infrastructure Damage report submitted successfully";
export const InfDamage_Report_Discard =
  "Infrastructure Damage report discarded successfully";
//#endregion Infrastructure Damage Report

/**Constants for showing the message for Infrastructure Other Damages
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 14-11-2022
 */
export const InfOtherDamage_Added =
  "Infrastructure Other Damage Added Successfully";
export const InfOtherDamage_Updated =
  "Infrastructure Other Damage Updated Successfully";
export const InfOtherDamage_Imported =
  "Infrastructure Other Damage Imported Successfully";

//#region Erosion Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**Constants for showing the message for Erosion */
export const Erosion_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Erosion_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Erosion_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Erosion_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Erosion_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Erosion_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Erosion_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Erosion_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Erosion_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Erosion_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Erosion_Imported = "Erosion Imported Successfully";
export const Erosion_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Erosion_Report_Submit = "Erosion report submitted successfully";
export const Erosion_Report_Discard = "Erosion report discarded successfully";

export const Erosion_Camp_Inmates_Imported =
  "Camp Inmates Imported Successfully";
export const Erosion_Non_Camp_Inmates_Imported =
  "Non Camp Inmates Imported Successfully";

//#region
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */

export const Erosion_Report_Approved = "Erosion report approved successfully";
export const Erosion_Report_Disapproved =
  "Erosion report disapproved successfully";
export const Erosion_Report_Verify = "Erosion report verified successfully";
export const Erosion_Freeze_Successfully =
  "Erosion Report Freezed/Unfreezed successfully";

//#endregion

export const Erosion_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Erosion_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Erosion_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Erosion_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Erosion_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Erosion_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Erosion_Added_NonCampInmates_Section =
  "Non Camp Inmates Section Details Added Successfully";
export const Erosion_Updated_NonCampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Erosion_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Erosion_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Erosion_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Erosion_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Erosion_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Erosion_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Erosion_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Erosion_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Erosion_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Erosion_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Erosion_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Erosion_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Erosion_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Erosion_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Erosion_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Erosion_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Erosion_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";
//#endregion Erosion Report

/**Constants for showing the message for River */
export const River_Added = "River Added Successfully";
export const River_Updated = "River Updated Successfully";
export const River_Imported = "River Imported Successfully";

/**Constants for showing the message for Agency */
export const Agency_Added = "Agency Added Successfully";
export const Agency_Updated = "Agency Updated Successfully";
export const Agency_Imported = "Agency Imported Successfully";

/**Constants for showing the message for RescueEquipment */
export const RescueEquipment_Added = "Rescue Equipment Added Successfully";
export const RescueEquipment_Updated = "Rescue Equipment Updated Successfully";
export const RescueEquipment_Imported =
  "Rescue Equipment Imported Successfully";

/**Constants for showing the message for ReliefOtherItem */
export const ReliefOtherItem_Added = "Relief Other Item Added Successfully";
export const ReliefOtherItem_Updated = "Relief Other Item Updated Successfully";
export const ReliefOtherItem_Imported =
  "Relief Other Item Imported Successfully";

/**Constants for showing the message for Unit */
export const Unit_Added = "Unit Added Successfully";
export const Unit_Updated = "Unit Updated Successfully";
export const Unit_Imported = "Unit Imported Successfully";

//#region Landslide Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 07-12-2022

/**Constants for showing the message for Landslide */
export const Landslide_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Landslide_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Landslide_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Landslide_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Landslide_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Landslide_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Landslide_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Landslide_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Landslide_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Landslide_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Landslide_Imported = "Landslide Imported Successfully";
export const Landslide_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Landslide_Report_Submit =
  "Landslide report submitted successfully";
export const Landslide_Report_Discard =
  "Landslide report discarded successfully";

export const Landslide_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Landslide_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Landslide_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Landslide_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Landslide_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Landslide_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Landslide_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Landslide_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Landslide_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Landslide_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Landslide_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Landslide_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Landslide_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Landslide_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Landslide_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Landslide_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Landslide_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Landslide_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Landslide_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Landslide_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Landslide_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Landslide_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Landslide_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";

/**
 * Constants for Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Approval and Disapproval
export const Landslide_Report_Approved =
  "Landslide report approved successfully";
export const Landslide_Report_Disapproved =
  "Landslide report disapproved successfully";
export const Landslide_Report_Verify = "Landslide report verified successfully";
//#endregion Approval and Disapproval

//#endregion Landslide Report

//#region UrbanFlood Report
//@author: shivam.sharma2@velsof.com
//@date : 22-12-2022

/**Constants for showing the message for UrbanFlood */
export const UrbanFlood_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const UrbanFlood_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const UrbanFlood_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const UrbanFlood_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const UrbanFlood_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const UrbanFlood_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const UrbanFlood_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const UrbanFlood_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const UrbanFlood_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const UrbanFlood_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const UrbanFlood_Imported = "UrbanFlood Imported Successfully";
export const UrbanFlood_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const UrbanFlood_Report_Submit =
  "UrbanFlood report submitted successfully";
export const UrbanFlood_Report_Discard =
  "UrbanFlood report discarded successfully";

//#region
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */

export const UrbanFlood_Report_Approved =
  "UrbanFlood report approved successfully";
export const UrbanFlood_Report_Disapproved =
  "UrbanFlood report disapproved successfully";
export const UrbanFlood_Report_Verify =
  "UrbanFlood report verified successfully";

//#endregion

export const UrbanFlood_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const UrbanFlood_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const UrbanFlood_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const UrbanFlood_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const UrbanFlood_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const UrbanFlood_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const UrbanFlood_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const UrbanFlood_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const UrbanFlood_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const UrbanFlood_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const UrbanFlood_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const UrbanFlood_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const UrbanFlood_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const UrbanFlood_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const UrbanFlood_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const UrbanFlood_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const UrbanFlood_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const UrbanFlood_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const UrbanFlood_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const UrbanFlood_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const UrbanFlood_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const UrbanFlood_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const UrbanFlood_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";
//#endregion UrbanFlood Report

/**Constants for showing the message for Wards Name */
export const Wards_Imported = "Wards Imported Successfully";
//#region Storm Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 09-12-2022

//#region Flood Report
//@author: dhruv.kumar@velsof.com
//@date : 21-07-2023

/**Constants for showing the message for Flood */
export const Flood_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Flood_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Flood_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Flood_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Flood_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Flood_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Flood_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Flood_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Flood_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Flood_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Flood_Imported = "Flood Imported Successfully";
export const Flood_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Flood_Report_Submit = "Flood report submitted successfully";
export const Flood_Report_Discard = "Flood report discarded successfully";

//#region
/**
 * @author: dhruv.kumar@velsof.com
 * @date : 21-07-2023
 */

export const Flood_Report_Approved = "Flood report approved successfully";
export const Flood_Report_Disapproved = "Flood report disapproved successfully";
export const Flood_Report_Verify = "Flood report verified successfully";
export const Flood_Freeze_Successfully =
  "Flood Report Freezed/Unfreezed successfully";

//#endregion

export const Flood_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Flood_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Flood_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Flood_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Flood_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";

//#region Camp inmates constant
//@author: shivam.sharma2@velsof.com
//@date : 28-12-2022
export const Flood_Added_NonCampInmates_Section =
  "Non Camp Inmates Section Details Added Successfully";

//#endregion Camp Inmates Section
export const Flood_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Flood_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Flood_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Flood_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Flood_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Flood_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Flood_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Flood_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Flood_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Flood_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Flood_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Flood_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Flood_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Flood_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Flood_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Flood_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Flood_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Flood_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";

export const Flood_Camp_Inmates_Imported = "Camp Inmates Imported Successfully";
export const Flood_Non_Camp_Inmates_Imported =
  "Non Camp Inmates Imported Successfully";
//#endregion Flood Report

/**Constants for showing the message for Storm */
export const Storm_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Storm_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Storm_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Storm_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Storm_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Storm_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Storm_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Storm_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Storm_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Storm_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Storm_Imported = "Storm Imported Successfully";
export const Storm_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Storm_Report_Submit = "Storm report submitted successfully";
export const Storm_Report_Discard = "Storm report discarded successfully";

export const Storm_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Storm_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Storm_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Storm_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Storm_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Storm_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Storm_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Storm_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Storm_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Storm_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Storm_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Storm_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Storm_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Storm_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Storm_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Storm_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Storm_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Storm_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Storm_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Storm_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Storm_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Storm_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Storm_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";

/**
 * Constants for Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Approval and Disapproval
export const Storm_Report_Approved = "Storm report approved successfully";
export const Storm_Report_Disapproved = "Storm report disapproved successfully";
export const Storm_Report_Verify = "Storm report verified successfully";
//#endregion Approval and Disapproval

//#endregion Storm Report

//#region Lightning Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 26-12-2022

/**Constants for showing the message for Lightning */
export const Lightning_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Lightning_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Lightning_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Lightning_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Lightning_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Lightning_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Lightning_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Lightning_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Lightning_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Lightning_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Lightning_Imported = "Lightning Imported Successfully";
export const Lightning_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Lightning_Report_Submit =
  "Lightning report submitted successfully";
export const Lightning_Report_Discard =
  "Lightning report discarded successfully";

export const Lightning_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Lightning_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Lightning_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Lightning_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Lightning_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Lightning_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Lightning_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Lightning_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Lightning_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Lightning_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Lightning_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Lightning_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Lightning_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Lightning_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Lightning_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Lightning_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Lightning_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Lightning_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Lightning_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Lightning_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Lightning_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Lightning_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Lightning_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";

/**
 * Constants for Approval and Disapproval
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 28-12-2022
 */
//#region Approval and Disapproval
export const Lightning_Report_Approved =
  "Lightning report approved successfully";
export const Lightning_Report_Disapproved =
  "Lightning report disapproved successfully";
export const Lightning_Report_Verify = "Lightning report verified successfully";
//#endregion Approval and Disapproval
//#endregion Lightning Report

//#region Earthquake Report
//@author: shivam.sharma2@velsof.com
//@date : 19-12-2022

/**Constants for showing the message for Earthquake */
export const Earthquake_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Earthquake_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Earthquake_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Earthquake_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Earthquake_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Earthquake_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Earthquake_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Earthquake_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Earthquake_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Earthquake_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Earthquake_Imported = "Earthquake Imported Successfully";
export const Earthquake_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Earthquake_Report_Submit =
  "Earthquake report submitted successfully";
export const Earthquake_Report_Discard =
  "Earthquake report discarded successfully";

//#region
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 19-12-2022
 */

export const Earthquake_Report_Approved =
  "Earthquake report approved successfully";
export const Earthquake_Report_Disapproved =
  "Earthquake report disapproved successfully";
export const Earthquake_Report_Verify =
  "Earthquake report verified successfully";

//#endregion

export const Earthquake_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Earthquake_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Earthquake_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Earthquake_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Earthquake_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Earthquake_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Earthquake_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Earthquake_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Earthquake_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Earthquake_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Earthquake_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Earthquake_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Earthquake_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Earthquake_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Earthquake_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Earthquake_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Earthquake_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Earthquake_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Earthquake_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Earthquake_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Earthquake_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Earthquake_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Earthquake_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";
//#endregion Earthquake Report

//#region User Management

/**Constants for showing the message for User */
export const User_Added = "User Added Successfully";
export const User_Updated = "User Updated Successfully";
export const User_Imported = "User Imported Successfully";
export const Saved_Password = "Password Saved Successfully";
export const User_Change_Password = "Password Changed Successfully";
//#endregion

export const Fetch_Villages_Not_Found = "Villages not found";
export const Add_More_Limit_Reached = "Limit has been reached";

//#region Fire Report
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**Constants for showing the message for Fire */
export const Fire_Added_Basic_Section =
  "Basic Section Details Added Successfully";
export const Fire_Updated_Basic_Section =
  "Basic Section Details Updated Successfully";

export const Fire_Added_PopAffected_Section =
  "Population Affected Section Details Added Successfully";
export const Fire_Updated_PopAffected_Section =
  "Population Affected Section Details Updated Successfully";

export const Fire_Added_InfDamage_Section =
  "Infrastructure Damage Section Details Added Successfully";
export const Fire_Updated_InfDamage_Section =
  "Infrastructure Damage Section Details Updated Successfully";

export const Fire_Added_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Added Successfully";
export const Fire_Updated_InfDamageOther_Section =
  "Infrastructure Damage Other Section Details Updated Successfully";

export const Fire_Added_Remark_Section =
  "Remark Section Details Added Successfully";
export const Fire_Updated_Remark_Section =
  "Remark Section Details Updated Successfully";

export const Fire_Imported = "Fire Imported Successfully";
export const Fire_Damage_Number =
  "Add Details should be less than and equal to ${0}. Please remove extra details";

export const Fire_Report_Submit = "Fire report submitted successfully";
export const Fire_Report_Discard = "Fire report discarded successfully";

//#region
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */

export const Fire_Report_Approved = "Fire report approved successfully";
export const Fire_Report_Disapproved = "Fire report disapproved successfully";
export const Fire_Report_Verify = "Fire report verified successfully";

//#endregion

export const Fire_Added_Eroded_Section =
  "Eroded Section Details Added Successfully";
export const Fire_Updated_Eroded_Section =
  "Eroded Section Details Updated Successfully";

export const Fire_Added_ReliefCamp_Section =
  "Relief Camp Section Details Added Successfully";
export const Fire_Updated_ReliefCamp_Section =
  "Relief Camp Section Details Updated Successfully";

export const Fire_Added_CampInmates_Section =
  "Camp Inmates Section Details Added Successfully";
export const Fire_Updated_CampInmates_Section =
  "Camp Inmates Section Details Updated Successfully";

export const Fire_Confirm_Number =
  "Confirmed Add Details should be equal to {0}.";
export const Fire_Missing_Number =
  "Missing/Injuired Add Details should be equal to {0}.";

export const Fire_Owner_Number =
  "Owner Add Details should be should be equal to {0}.";
export const Fire_Occupant_Number =
  "Occupant Add Details should be equal to {0}.";

export const Fire_Added_HLL_Section =
  "Human Live Lost Section Details Added Successfully";
export const Fire_Updated_HLL_Section =
  "Human Live Lost Section Details Updated Successfully";

export const Fire_Added_Property_Owner_Section =
  "Name & Address of Owner of the Property & Occupant Section Details Added Successfully";
export const Fire_Updated_Property_Owner_Section =
  "Name & Address of Owner of the Property & Occupant Section Details Updated Successfully";

export const Fire_Added_ESPL_Section =
  "Emergency Service Personnel Life Section Details Added Successfully";

export const Fire_Added_LiveStock_Section =
  "LiveStock Section Details Added Successfully";
export const Fire_Updated_LiveStock_Section =
  "LiveStock Section Details Updated Successfully";

export const Fire_Added_HouseDamaged_Section =
  "House Damaged Section Details Added Successfully";
export const Fire_Added_Caller_Details_Section =
  "Caller Details Section Details Added Successfully";
export const Fire_Added_Turn_Out_Details_Section =
  "Turn Out Details Section Details Added Successfully";
export const Fire_Added_Fire_Details_Section =
  "Fire Details Section Details Added Successfully";
export const Fire_Added_Property_Involved_Section =
  "Property Involved/Gutted Details Section Details Added Successfully";
export const Fire_Added_Owner_of_Property_Section =
  "Name & Address of Owner of the Property & Occupant Section Details Added Successfully";
export const Fire_Updated_HouseDamaged_Section =
  "House Damaged Section Details Updated Successfully";

export const Fire_Added_RescueOperation_Section =
  "Rescue Operation Section Details Added Successfully";
export const Fire_Updated_RescueOperation_Section =
  "Rescue Operation Section Details Updated Successfully";

export const Fire_Added_ReliefDistribution_Section =
  "Relief Distribution Section Details Added Successfully";
export const Fire_Updated_ReliefDistribution_Section =
  "Relief Distribution Section Details Updated Successfully";

export const Fire_Embankment_Breached_Number =
  "Embankment Breached Details of should be less than and equal to {0}. Please remove extra details";
export const Fire_Embankment_Affected_Number =
  "Embankment Affected Details should be less than and equal to {0}. Please remove extra details";
export const Fire_Road_Affected_Number =
  "Road Affected Details should be less than and equal to {0}. Please remove extra details";
export const Fire_Bridge_Affected_Number =
  "Bridge Affected Details should be less than and equal to {0}. Please remove extra details";

export const Fire_Other_Damage_Number =
  "Other Damage Details of Row {0} should be less than and equal to {1}. Please remove extra details";
//#endregion Fire Report

/**
 * Added for report export url
 * @author: shivam.sharma2@velsof.com
 * @date : 17-01-2023
 */
//#region Fire Category
export const Fire_Categories_Imported = "Fire Categories Imported Successfully";
//#endregion

//#region Fire Cause
export const Fire_Cause_Imported = "Fire Causes Imported Successfully";
//#endregion

//#region Fire Class
export const Fire_Class_Imported = "Fire Class Imported Successfully";
//#endregion

//#region Special Call
export const Special_Call_Type_Imported =
  "Special Call Type Imported Successfully";
//#endregion

//#region Origin Area
export const Origin_Area_Imported = "Origin Area Imported Successfully";
//#endregion

//#region Fire Detection
export const Fire_Detection_Imported = "Fire Detection Imported Successfully";
//#endregion

//#region Fire Agency
export const Fire_Agency_Imported = "Fire Agency Imported Successfully";
//#endregion

//#region Types of Occupancy
export const Types_of_Occupancy_Imported =
  "Types of Occupancy Imported Successfully";
//#endregion

//#region Vehicle Categories
export const Vehicle_Categories_Imported =
  "Vehicle Categories Imported Successfully";
//#endregion

//#region Station
export const Station_Imported = "Station Imported Successfully";
//#endregion

//#region Livestocks
export const Livestock_Imported = "Livestocks Imported Successfully";
//#endregion

//#region for Property damaged section
export const houseDamagedFieldsWarnings = {
  pdSmallFireLoss: "Property Involved value must be smaller than 50,000",
  pdMediumFireLoss:
    "Property Involved value must be equal or greater than 50,000 & smaller than 1,00,000",
  pdSeriousFireLoss: "",
  pdMajorFireLoss:
    "Property Involved value must be greater or equal to 1,00,000",
  piSmallFireLoss: "Property Damaged value must be smaller than 50,000",
  piMediumFireLoss:
    "Property Damaged value must be equal or greater than 50,000 & smaller than 1,00,000",
  piSeriousFireLoss: "",
  piMajorFireLoss:
    "Property Damaged value must be greater or equal to 1,00,000",
};
export const propertyDamagedFieldWarning =
  "Property Damaged value must be smaller or equal to the Property Involved";
//#endregion

//#region for remark section in fire form
export const imageSizeExceed =
  "Uploaded file must be equal or smaller than 5MB";
//#endregion

/**
 * Added for delete incident photo
 * @author: shivam.sharma2@velsof.com
 * @date : 02-01-2023
 */
export const Fire_Incident_Photo_Deleted = "Image deleted successfully";

/**Constants for showing the message for Roles */
export const Role_Added = "Role Added Successfully";
export const Role_Updated = "Role Updated Successfully";

export const Disaster_Not_Allowed =
  "You are not authorized to access this reporting";

/**Constants for showing the message for Resource Group */
export const ResourceGroup_Added = "Resource Group Added Successfully";
export const ResourceGroup_Updated = "Resource Group Updated Successfully";

/**Constants for showing the message for Resource Document */
export const ResourceDocument_Added = "Resource Document Added Successfully";
export const ResourceDocument_Updated =
  "Resource Document Updated Successfully";
