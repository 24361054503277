/**
 * The below file contains the constants used in the erosion report.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */

/**Constants for erosion Listing page */
export const GET_ALL_EROSION = "GET_ALL_EROSION";
export const SET_EROSION_LISTING = "SET_EROSION_LISTING";
export const SET_EROSION_LISTING_PARAM = "SET_EROSION_LISTING_PARAM";
export const GET_EXPORT_EROSION_DATA = "GET_EXPORT_EROSION_DATA";
export const SET_EXPORT_EROSION_DATA = "SET_EXPORT_EROSION_DATA";
/**Constants for erosion Listing page */

/**Constants for erosion Import page */
export const EROSION_IMPORT = "EROSION_IMPORT";
/**Constants for erosion Import page */

//#region All Section
/**Constants for all section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */
export const EROSION_GET_ALL_SECTION = "EROSION_GET_ALL_SECTION";
export const EROSION_SET_ALL_SECTION = "EROSION_SET_ALL_SECTION";
export const EROSION_SET_RELIEF_CENTER_SECTION =
  "EROSION_SET_RELIEF_CENTER_SECTION";
export const EROSION_RESET_BUTTON_LOADING = "EROSION_RESET_BUTTON_LOADING";
export const EROSION_SET_PARENT_ID = "EROSION_SET_PARENT_ID";
export const EROSION_REPORT_SUBMIT = "EROSION_REPORT_SUBMIT";
export const EROSION_REPORT_DISCARD = "EROSION_REPORT_DISCARD";
export const EROSION_SET_REDIRECT_TO_LISTING =
  "EROSION_SET_REDIRECT_TO_LISTING";
export const EROSION_SET_DATA_SAVED = "EROSION_SET_DATA_SAVED";
//#endregion All Section

//#region Basic Section
/**Constants for adding the basic section details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */
export const EROSION_ADD_BASIC_SECTION = "EROSION_ADD_BASIC_SECTION";
export const EROSION_EDIT_BASIC_SECTION = "EROSION_EDIT_BASIC_SECTION";
export const EROSION_SET_NIL_REPORTING = "EROSION_SET_NIL_REPORTING";
//#endregion Basic Section

//#region InfDamage Section
/**Constants for adding the infrastructure damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */
export const EROSION_ADD_INFDAMAGE_SECTION = "EROSION_ADD_INFDAMAGE_SECTION";
//#endregion InfDamage Section

//#region InfDamageOther Section
/**Constants for adding the infrastructure others damage details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */
export const EROSION_ADD_INFDAMAGEOTHER_SECTION =
  "EROSION_ADD_INFDAMAGEOTHER_SECTION";
//#endregion InfDamageOther Section

//#region Remark Section
/**Constants for adding the Remark details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */
export const EROSION_ADD_REMARK_SECTION = "EROSION_ADD_REMARK_SECTION";
//#endregion Remark Section

//#region PopAffected Section
/**Constants for adding the population affected details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 21-11-2022
 */
export const EROSION_ADD_POPAFFECTED_SECTION =
  "EROSION_ADD_POPAFFECTED_SECTION";
//#endregion PopAffected Section

//#region Eroded Section
/**Constants for adding the eroded details
 * @author: dhruv.kumar@velsof.com
 * @date : 24-11-2022
 */
export const EROSION_ADD_ERODED_SECTION = "EROSION_ADD_ERODED_SECTION";
//#endregion Eroded Section

//#region Relief Camp Section
/**Constants for adding the relief camp details
 * @author: dhruv.kumar@velsof.com
 * @date : 25-11-2022
 */
export const EROSION_ADD_RELIEFCAMP_SECTION = "EROSION_ADD_RELIEFCAMP_SECTION";
//#endregion relief camp Section

//#region Camp Inmates Section
/**Constants for adding the camp Inmates details
 * @author: dhruv.kumar@velsof.com
 * @date : 25-11-2022
 */
export const EROSION_ADD_CAMPINMATES_SECTION =
  "EROSION_ADD_CAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Camp Non Inmates Section
/**Constants for adding the camp Inmates details
 * @author: vishal.mishra@velsof.com
 * @date : 21-01-2025
 */
export const EROSION_ADD_NONCAMPINMATES_SECTION =
  "EROSION_ADD_NONCAMPINMATES_SECTION";
//#endregion Camp Inmates Section

//#region Human Live Lost Section
/**Constants for adding the Human Live Lost details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
export const EROSION_ADD_HLL_SECTION = "EROSION_ADD_HLL_SECTION";
//#endregion Human Live Lost Section

//#region LiveStock Section
/**Constants for adding the LiveStock details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
export const EROSION_ADD_LIVESTOCK_SECTION = "EROSION_ADD_LIVESTOCK_SECTION";
//#endregion LiveStock Section

//#region HouseDamaged Section
/**Constants for adding the HouseDamaged details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
export const EROSION_ADD_HOUSEDAMAGED_SECTION =
  "EROSION_ADD_HOUSEDAMAGED_SECTION";
//#endregion HouseDamaged Section

//#region RescueOperation Section
/**Constants for adding the RescueOperation details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
export const EROSION_ADD_RESCUEOPERATION_SECTION =
  "EROSION_ADD_RESCUEOPERATION_SECTION";
//#endregion RescueOperation Section

//#region ReliefDistribution Section
/**Constants for adding the ReliefDistribution details
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 01-12-2022
 */
export const EROSION_ADD_RELIEFDISTRIBUTION_SECTION =
  "EROSION_ADD_RELIEFDISTRIBUTION_SECTION";
//#endregion ReliefDistribution Section

/**Constants for adding the approve & disapprove constants
 * @author: shivam.sharma2@velsof.com
 * @date : 09-12-2022
 */
export const EROSION_REVENUE_REPORT_APPROVE = "EROSION_REVENUE_REPORT_APPROVE";
export const EROSION_REVENUE_REPORT_DISAPPROVE =
  "EROSION_REVENUE_REPORT_DISAPPROVE";
export const EROSION_REVENUE_REPORT_VERIFY = "EROSION_REVENUE_REPORT_VERIFY";

export const EXPORT_FO_LEVEL_EROSION_REPORT = "EXPORT_FO_LEVEL_EROSION_REPORT";
export const SET_EXPORT_FO_LEVEL_EROSION_REPORT =
  "SET_EXPORT_FO_LEVEL_EROSION_REPORT";

export const EROSION_DISTRICT_REPORT_APPROVE =
  "EROSION_DISTRICT_REPORT_APPROVE";
export const EROSION_DISTRICT_REPORT_DISAPPROVE =
  "EROSION_DISTRICT_REPORT_DISAPPROVE";
export const EROSION_DISTRICT_REPORT_VERIFY = "EROSION_DISTRICT_REPORT_VERIFY";

export const EROSION_STATE_REPORT_APPROVE = "EROSION_STATE_REPORT_APPROVE";
export const EROSION_STATE_REPORT_DISAPPROVE =
  "EROSION_STATE_REPORT_DISAPPROVE";

//#region Approval and Disapproval
export const GET_EROSION_DISTRICT_REVIEW_LIST =
  "GET_EROSION_DISTRICT_REVIEW_LIST";
export const SET_EROSION_DISTRICT_REVIEW_LIST = 
"SET_EROSION_DISTRICT_REVIEW_LIST";
export const GET_EROSION_STATE_REVIEW_LIST = "GET_EROSION_STATE_REVIEW_LIST";
export const SET_EROSION_STATE_REVIEW_LIST = "SET_EROSION_STATE_REVIEW_LIST";
//#endregion Approval and Disapproval

export const SET_DISTRICT_EROSION_REPORT = "SET_DISTRICT_EROSION_REPORT";
export const GET_DISTRICT_EROSION_REPORT = "GET_DISTRICT_EROSION_REPORT";
export const GET_STATE_CUMULATIVE_EROSION_REPORT =
  "GET_STATE_CUMULATIVE_EROSION_REPORT";
export const SET_STATE_CUMULATIVE_EROSION_REPORT =
  "SET_STATE_CUMULATIVE_EROSION_REPORT";
export const GET_DISTRICT_CUMULATIVE_EROSION_REPORT =
  "GET_DISTRICT_CUMULATIVE_EROSION_REPORT";
export const SET_DISTRICT_CUMULATIVE_EROSION_REPORT =
  "SET_DISTRICT_CUMULATIVE_EROSION_REPORT";

//#region for flood form freeze unfreeze report for a selected Range
export const FREEZE_UNFREEZE_EROSION_RANGE_REPORT =
  "FREEZE_UNFREEZE_EROSION_RANGE_REPORT";