/**
 * The below file is the erosion service which contains method for doing the API calls.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */

import fetch from "auth/FetchInterceptor";
import {
  Application_Json,
  Authorization_Token,
  Blob,
  Get_All_Erosion,
  Get_Export_Erosion,
  Multipart_Form_Data,
  Erosion_Import,
  Erosion_Add_Basic_Section,
  Erosion_Edit_Basic_Section,
  Erosion_Get_All_Section,
  Erosion_Add_InfDamage_Section,
  Erosion_Add_InfDamageOther_Section,
  Erosion_Add_Remark_Section,
  Erosion_Submit_Report,
  Erosion_Discard_Report,
  Erosion_Add_PopAffected_Section,
  Erosion_Add_Eroded_Section,
  Erosion_Add_ReliefCamp_Section,
  Erosion_Add_CampInmates_Section,
  Erosion_Add_HLL_Section,
  Erosion_Add_LiveStock_Section,
  Erosion_Add_HouseDamaged_Section,
  Erosion_Add_RescueOperation_Section,
  Erosion_Add_ReliefDistribution_Section,
  Erosion_Fo_Level_Report,
  Erosion_Cumulative_State_Report,
  Erosion_Cumulative_District_Report,
  Erosion_Get_District_Review_List,
  Erosion_Revenue_Report_Verify,
  Erosion_Revenue_Report_Disapprove,
  Erosion_Revenue_Report_Approve,
  Erosion_Get_State_Review_List,
  Erosion_District_Report_Approve,
  Erosion_District_Report_Disapprove,
  Erosion_District_Report_Verify,
  Erosion_State_Report_Approve,
  Erosion_State_Report_Disapprove,
  Erosion_Add_NonCampInmates_Section,
} from "constants/ApiConstant";
import { format } from "react-string-format";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { Erosion_State_Freeze_Unfreeze_Report_Range } from "../constants/ApiConstant";

const ErosionService = {};

//#region Method for listing page.

/**
 * Method for getting the erosion listing
 * @param {any} param API Parameters
 * @returns Fetch Method
 */
ErosionService.getAllErosion = function (param) {
  return fetch({
    url: Get_All_Erosion,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**
 * Method for getting the erosion export data
 * @returns Fetch Method
 */
ErosionService.getExportErosionData = function () {
  return fetch({
    url: Get_Export_Erosion,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    responseType: Blob,
  });
};
//#endregion Method for listing page.

//#region Method for erosion import page.

/**
 * Method for importing the erosion
 * @param {any} data API data
 * @returns Fetch Method
 */
ErosionService.erosionImport = function (data) {
  return fetch({
    url: Erosion_Import,
    method: "post",
    headers: {
      "Content-Type": Multipart_Form_Data,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Method for erosion import page.

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**Method for getting all section data
 * @param {number} id Form Id
 * @param {object} param Param object
 * @returns Fetch Method
 */
ErosionService.erosionGetAllSection = function (id, param) {
  return fetch({
    url: format(Erosion_Get_All_Section, id),
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: param,
  });
};

/**Method for submitting the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
ErosionService.erosionReportSubmit = function (id) {
  return fetch({
    url: format(Erosion_Submit_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};

/**Method for discarding the report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
ErosionService.erosionReportDiscard = function (id) {
  return fetch({
    url: format(Erosion_Discard_Report, id),
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
  });
};
//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the basic section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddBasicSection = function (data) {
  return fetch({
    url: Erosion_Add_Basic_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

/**
 * Method for editing the basic section
 * @param {number} id Form Id
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionEditBasicSection = function (id, data) {
  return fetch({
    url: format(Erosion_Edit_Basic_Section, id),
    method: "put",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamage section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddInfDamageSection = function (data) {
  return fetch({
    url: Erosion_Add_InfDamage_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the infDamageOther section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddInfDamageOtherSection = function (data) {
  return fetch({
    url: Erosion_Add_InfDamageOther_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for adding the remark section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddRemarkSection = function (data) {
  return fetch({
    url: Erosion_Add_Remark_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};

//#endregion Remark Section

//#region Popaffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 21-11-2022

/**
 * Method for adding the popaffected section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddPopAffectedSection = function (data) {
  return fetch({
    url: Erosion_Add_PopAffected_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Popaffected Section

//#region Eroded Section
//@author: dhruv.kumar@velsof.com
//@date : 24-11-2022

/**
 * Method for adding the eroded section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddErodedSection = function (data) {
  return fetch({
    url: Erosion_Add_Eroded_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion eroded Section

//#region Relief Camp Section
//@author: dhruv.kumar@velsof.com
//@date : 25-11-2022

/**
 * Method for adding the relief camp section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddReliefCampSection = function (data) {
  return fetch({
    url: Erosion_Add_ReliefCamp_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion relief camp Section

//#region Camp inmates Section
/**
 * Method for adding the camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddCampInmatesSection = function (data) {
  return fetch({
    url: Erosion_Add_CampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion camp inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 21-01-2025
/**
 * Method for adding the non camp inmates section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddNonCampInmatesSection = function (data) {
  return fetch({
    url: Erosion_Add_NonCampInmates_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion non camp inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Human Live Lost section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddHLLSection = function (data) {
  return fetch({
    url: Erosion_Add_HLL_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the LiveStock section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddLiveStockSection = function (data) {
  return fetch({
    url: Erosion_Add_LiveStock_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion LiveStock Section

//#region House Damaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the House Damaged section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddHouseDamagedSection = function (data) {
  return fetch({
    url: Erosion_Add_HouseDamaged_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion House Damaged Section

//#region Rescue Operation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Rescue Operation section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddRescueOperationSection = function (data) {
  return fetch({
    url: Erosion_Add_RescueOperation_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Rescue Operation Section

//#region Relief Distribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022
/**
 * Method for adding the Relief Distribution section
 * @param {object} data API data
 * @returns Fetch Method
 */
ErosionService.erosionAddReliefDistributionSection = function (data) {
  return fetch({
    url: Erosion_Add_ReliefDistribution_Section,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: data,
  });
};
//#endregion Relief Distribution Section

//#region Method for Approve and Disapprove
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */
/**Method for approving the revenue report
 * @param {number} id Form Id
 * @returns Fetch Method
 */
ErosionService.erosionRevenueReportApprove = function (payload) {
  return fetch({
    url: Erosion_Revenue_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 19-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionRevenueReportDisapprove = function (payload) {
  return fetch({
    url: Erosion_Revenue_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the revenue report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 19-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionRevenueReportVerify = function (payload) {
  return fetch({
    url: Erosion_Revenue_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the district approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionDistrictReportApprove = function (payload) {
  return fetch({
    url: Erosion_District_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionDistrictReportDisapprove = function (payload) {
  return fetch({
    url: Erosion_District_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionDistrictReportVerify = function (payload) {
  return fetch({
    url: Erosion_District_Report_Verify,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method to approve the state approve
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {object} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionStateReportApprove = function (payload) {
  return fetch({
    url: Erosion_State_Report_Approve,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionStateReportDisapprove = function (payload) {
  return fetch({
    url: Erosion_State_Report_Disapprove,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};

/**
 * Method for getting the district review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
ErosionService.getErosionDistrictReviewList = function (payload) {
  return fetch({
    url: Erosion_Get_District_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};

/**
 * Method for getting the state review list
 * @param {object} payload Payload
 * @returns Fetch Method
 */
ErosionService.getErosionStateReviewList = function (payload) {
  return fetch({
    url: Erosion_Get_State_Review_List,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: payload,
  });
};
//#endregion Method for Approve and Disapprove

//#region
/**
 * Below method is for downloading the exported file for viewErosion.js
 */
ErosionService.getExportFoLevelErosionReport = function (data) {
  return fetch({
    url: Erosion_Fo_Level_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative state report
 * @author: shivam.sharma2@velsof.com
 * @date : 16-12-2022
 */
ErosionService.getExportStateCumulativeErosionReport = function (data) {
  return fetch({
    url: Erosion_Cumulative_State_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};
//#endregion

//#region
/**
 * Below method is for downloading the exported file for Cumulative district report
 * @author: shivam.sharma2@velsof.com
 * @date : 16-12-2022
 */
ErosionService.getExportDistrictCumulativeErosionReport = function (data) {
  return fetch({
    url: Erosion_Cumulative_District_Report,
    method: "get",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    params: data,
    responseType: Blob,
  });
};

/**
 * Method for Freezing/Unfreezing flood report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 03-04-2024
 * @param {any} payload Payload
 * @returns Fetch Method
 */
ErosionService.erosionFreezeReportRange = function (payload) {
  return fetch({
    url: Erosion_State_Freeze_Unfreeze_Report_Range,
    method: "post",
    headers: {
      "Content-Type": Application_Json,
      Authorization: format(
        Authorization_Token,
        localStorage.getItem(AUTH_TOKEN)
      ),
    },
    data: payload,
  });
};
//#endregion

export default ErosionService;
