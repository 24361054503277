/**
 * The below file contains the actions used in the Non Camp Inmates Registration.
 * @author: vishal.mishra@velsof.com
 * @date :01-04-2024
 */

import {
  NON_CAMP_INMATES_REGISTRATION_IMPORT,
  GET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION,
  GET_ALL_EROSION_NON_INMATES_REGISTRATION,
  GET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION,
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION,
  SET_ALL_EROSION_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_EROSION_NON_INMATES_REGISTRATION,
} from "redux/constants/ErosionNonInmatesRegistration";

// Import the constants you defined

// Action to fetch the Non Camp Inmates Registration
export const erosionGetAllNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const erosionSetAllNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const erosionGetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: GET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const erosionSetAllCreateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

// Action to fetch the Non Camp Inmates Registration
export const erosionGetAllUpdateNonInmatesRegistration = (data, id) => {
  return {
    type: GET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
    id: id,
  };
};

// Action to fetch a new Non Camp Inmates Registration
export const erosionSetAllUpdateNonInmatesRegistration = (data) => {
  return {
    type: SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for setting the Erosion Non Inmates Registration parameters
 * @param {any} data Erosion Data
 * @returns Json Object
 */
export const erosionSetNonInmatesRegistrationTableParam = (data) => {
  return {
    type: SET_EROSION_NON_INMATES_REGISTRATION,
    payload: data,
  };
};

/**
 * Method for Non Camp Inmates Registration import
 * @param {any} data Camp Inmates Registration Data
 * @returns Json Object
 */
export const erosionNonCampInmatesRegistrationImport = (data) => {
  return {
    type: NON_CAMP_INMATES_REGISTRATION_IMPORT,
    payload: data,
  };
};

/**
 * Method for getting the export file of Non Camp Inmates Registration
 * @returns Json Object
 */
export const erosionGetExportNonInmatesRegistration = (data) => {
  return {
    type: GET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};

/**
 * Method for setting the reliefCamp export data in the state
 * @param {any} data Relief Camp Data
 * @returns Json Object
 */
export const erosionSetExportNonInmatesRegistration = (data) => {
  return {
    type: SET_EXPORT_NON_CAMP_INMATES_DATA,
    payload: data,
  };
};
