/**
 * The below file contains the Sagas method for Erosion reports.
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 17-11-2022
 */

import { Error, Success } from "constants/ApiConstant";
import {
  BasicSection,
  DiscardButton,
  InfDamageOtherSection,
  InfDamageSection,
  PopAffectedSection,
  ErodedSection,
  RemarkSection,
  SubmitButton,
  approveButton,
  disapproveButton,
  ReliefCampSection,
  CampInmatesSection,
  HllSection,
  LiveStockSection,
  HouseDamagedSection,
  RescueOperationSection,
  ReliefDistributionSection,
  verifyButton,
} from "constants/ApplicationConstant";
import {
  Erosion_Added_Basic_Section,
  Erosion_Added_CampInmates_Section,
  Erosion_Added_Eroded_Section,
  Erosion_Added_HLL_Section,
  Erosion_Added_HouseDamaged_Section,
  Erosion_Added_InfDamageOther_Section,
  Erosion_Added_InfDamage_Section,
  Erosion_Added_LiveStock_Section,
  Erosion_Added_PopAffected_Section,
  Erosion_Added_ReliefCamp_Section,
  Erosion_Added_ReliefDistribution_Section,
  Erosion_Added_Remark_Section,
  Erosion_Added_RescueOperation_Section,
  Erosion_Imported,
  Erosion_Report_Discard,
  Erosion_Report_Submit,
  Erosion_Report_Approved,
  Erosion_Report_Disapproved,
  Erosion_Updated_Basic_Section,
  Erosion_Report_Verify,
} from "constants/MessageConstant";
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { showMessage } from "redux/actions/Common";
import {
  erosionResetButtonLoading,
  erosionSetAllSection,
  erosionSetDataSaved,
  erosionSetParentId,
  erosionSetRedirectToListing,
  setExportErosion,
  setErosionListing,
  setErosionDistrictReviewList,
  setErosionStateReviewList,
  setExportDistrictCumulativeErosionReport,
  setExportStateCumulativeErosionReport,
  setExportFOLevelErosionReport,
} from "redux/actions/Erosion";
import {
  GET_ALL_EROSION,
  GET_EXPORT_EROSION_DATA,
  EROSION_ADD_BASIC_SECTION,
  EROSION_ADD_INFDAMAGEOTHER_SECTION,
  EROSION_ADD_INFDAMAGE_SECTION,
  EROSION_ADD_REMARK_SECTION,
  EROSION_EDIT_BASIC_SECTION,
  EROSION_GET_ALL_SECTION,
  EROSION_IMPORT,
  EROSION_REPORT_DISCARD,
  EROSION_REPORT_SUBMIT,
  EROSION_ADD_POPAFFECTED_SECTION,
  EROSION_ADD_ERODED_SECTION,
  EROSION_ADD_RELIEFCAMP_SECTION,
  EROSION_ADD_CAMPINMATES_SECTION,
  EROSION_ADD_HLL_SECTION,
  EROSION_ADD_LIVESTOCK_SECTION,
  EROSION_ADD_HOUSEDAMAGED_SECTION,
  EROSION_ADD_RESCUEOPERATION_SECTION,
  EROSION_ADD_RELIEFDISTRIBUTION_SECTION,
  GET_EROSION_DISTRICT_REVIEW_LIST,
  GET_EROSION_STATE_REVIEW_LIST,
  EXPORT_FO_LEVEL_EROSION_REPORT,
  EROSION_REVENUE_REPORT_APPROVE,
  EROSION_REVENUE_REPORT_DISAPPROVE,
  EROSION_REVENUE_REPORT_VERIFY,
  EROSION_DISTRICT_REPORT_APPROVE,
  EROSION_DISTRICT_REPORT_DISAPPROVE,
  EROSION_DISTRICT_REPORT_VERIFY,
  EROSION_STATE_REPORT_APPROVE,
  EROSION_STATE_REPORT_DISAPPROVE,
  GET_DISTRICT_CUMULATIVE_EROSION_REPORT,
  GET_STATE_CUMULATIVE_EROSION_REPORT,
} from "redux/constants/Erosion";
import ErosionService from "services/ErosionService";
import {
  EROSION_ADD_NONCAMPINMATES_SECTION,
  FREEZE_UNFREEZE_EROSION_RANGE_REPORT,
} from "../constants/Erosion";
import {
  freezeButton,
  NonCampInmatesSection,
} from "../../constants/ApplicationConstant";
import {
  Erosion_Added_NonCampInmates_Section,
  Erosion_Freeze_Successfully,
} from "../../constants/MessageConstant";
import { erosionSetReliefCenterSection } from "../actions/Erosion";

//#region Method for listing

/**
 * Method for getting the erosions
 */
export function* getAllErosion() {
  yield takeEvery(GET_ALL_EROSION, function* ({ payload }) {
    try {
      const erosion = yield call(ErosionService.getAllErosion, payload);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(setErosionListing(erosion));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the erosion export details
 */
export function* getErosionExport() {
  yield takeEvery(GET_EXPORT_EROSION_DATA, function* () {
    try {
      const erosion = yield call(ErosionService.getExportErosionData);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        let exportData = {
          url: window.URL.createObjectURL(erosion),
        };
        yield put(setExportErosion(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Method for listing

//#region Method for erosion import page

/**
 * Method for importing the erosion
 */
export function* erosionImport() {
  yield takeEvery(EROSION_IMPORT, function* ({ payload }) {
    try {
      const erosion = yield call(ErosionService.erosionImport, payload);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(showMessage({ message: Erosion_Imported, type: Success }));
      }
    } catch (err) {
      yield put(
        showMessage({
          message: JSON.parse(err.response.data.error.message),
          type: Error,
        })
      );
    }
  });
}
//#endregion Method for erosion import page

//#region All Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/**
 * Method for getting the all section data
 */
export function* erosionGetAllSection() {
  yield takeEvery(EROSION_GET_ALL_SECTION, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        ErosionService.erosionGetAllSection,
        payload.id,
        payload.param
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(erosionSetAllSection(infDepartment));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for submitting the report
 */
export function* erosionReportSubmit() {
  yield takeEvery(EROSION_REPORT_SUBMIT, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        ErosionService.erosionReportSubmit,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Submit, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: SubmitButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: SubmitButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for discarding the report
 */
export function* erosionReportDiscard() {
  yield takeEvery(EROSION_REPORT_DISCARD, function* ({ payload }) {
    try {
      const infDepartment = yield call(
        ErosionService.erosionReportDiscard,
        payload.id
      );
      if (infDepartment.error != null && infDepartment.error.message) {
        yield put(
          showMessage({ message: infDepartment.error.message, type: Error })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Discard, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: DiscardButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: DiscardButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#endregion All Section

//#region Basic Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/** Method for adding basic section
 */
export function* erosionAddBasicSection() {
  yield takeEvery(EROSION_ADD_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };

      const erosion = yield call(ErosionService.erosionAddBasicSection, final);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionSetParentId(erosion.data.id));
        yield put(erosionResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({ message: Erosion_Added_Basic_Section, type: Success })
        );
        yield put(erosionSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/** Method for editing basic section
 */
export function* erosionEditBasicSection() {
  yield takeEvery(EROSION_EDIT_BASIC_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionEditBasicSection,
        payload.id,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: BasicSection }));
        yield put(
          showMessage({
            message: Erosion_Updated_Basic_Section,
            type: Success,
          })
        );
        yield put(erosionSetDataSaved({ section: BasicSection, value: true }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: BasicSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Basic Section

//#region InfDamage Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/** Method for adding infDamage section
 */
export function* erosionAddInfDamageSection() {
  yield takeEvery(EROSION_ADD_INFDAMAGE_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddInfDamageSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: InfDamageSection }));
        yield put(
          showMessage({
            message: Erosion_Added_InfDamage_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: InfDamageSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: InfDamageSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamage Section

//#region InfDamageOther Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/** Method for adding infDamageOther section
 */
export function* erosionAddInfDamageOtherSection() {
  yield takeEvery(EROSION_ADD_INFDAMAGEOTHER_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddInfDamageOtherSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(
          erosionResetButtonLoading({ section: InfDamageOtherSection })
        );
        yield put(
          showMessage({
            message: Erosion_Added_InfDamageOther_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({
            section: InfDamageOtherSection,
            value: true,
          })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: InfDamageOtherSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion InfDamageOther Section

//#region Remark Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 17-11-2022

/** Method for adding Remark section
 */
export function* erosionAddRemarkSection() {
  yield takeEvery(EROSION_ADD_REMARK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const Erosion = yield call(ErosionService.erosionAddRemarkSection, final);
      if (Erosion.error != null && Erosion.error.message) {
        yield put(showMessage({ message: Erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: RemarkSection }));
        yield put(
          showMessage({
            message: Erosion_Added_Remark_Section,
            type: Success,
          })
        );
        yield put(erosionSetDataSaved({ section: RemarkSection, value: true }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: RemarkSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Remark Section

//#region PopAffected Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 21-11-2022

/** Method for adding popaffected section
 */
export function* erosionAddPopAffectedSection() {
  yield takeEvery(EROSION_ADD_POPAFFECTED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddPopAffectedSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: PopAffectedSection }));
        yield put(
          showMessage({
            message: Erosion_Added_PopAffected_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: PopAffectedSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: PopAffectedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion PopAffected Section

//#region Eroded Section
//@author: dhruv.kumar@velsof.com
//@date : 24-11-2022

/** Method for adding eroded section
 */
export function* erosionAddErodedSection() {
  yield takeEvery(EROSION_ADD_ERODED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(ErosionService.erosionAddErodedSection, final);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: ErodedSection }));
        yield put(
          showMessage({
            message: Erosion_Added_Eroded_Section,
            type: Success,
          })
        );
        yield put(erosionSetDataSaved({ section: ErodedSection, value: true }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: ErodedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Eroded Section

//#region Relief Camp Section
//@author: dhruv.kumar@velsof.com
//@date : 25-11-2022

/** Method for adding relief camp section
 */
export function* erosionAddReliefCampSection() {
  yield takeEvery(EROSION_ADD_RELIEFCAMP_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddReliefCampSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: ReliefCampSection }));
        yield put(
          showMessage({
            message: Erosion_Added_ReliefCamp_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: ReliefCampSection, value: true })
        );
        yield put(
          erosionSetReliefCenterSection(erosion?.data?.data?.reliefCenterData)
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: ReliefCampSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Relief Camp Section

//#region Camp inmates Section
//@author: dhruv.kumar@velsof.com
//@date : 25-11-2022

/** Method for adding camp inmates section
 */
export function* erosionAddCampInmatesSection() {
  yield takeEvery(EROSION_ADD_CAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddCampInmatesSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: CampInmatesSection }));
        yield put(
          showMessage({
            message: Erosion_Added_CampInmates_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: CampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: CampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Camp Inmates Section

//#region Non Camp inmates Section
//@author: vishal.mishra@velsof.com
//@date : 21-01-2025

/** Method for adding non camp inmates section
 */
export function* erosionAddNonCampInmatesSection() {
  yield takeEvery(EROSION_ADD_NONCAMPINMATES_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddNonCampInmatesSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(
          erosionResetButtonLoading({ section: NonCampInmatesSection })
        );
        yield put(
          showMessage({
            message: Erosion_Added_NonCampInmates_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: NonCampInmatesSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: NonCampInmatesSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Non Camp Inmates Section

//#region Human Live Lost Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding Human Live Lost section
 */
export function* erosionAddHLLSection() {
  yield takeEvery(EROSION_ADD_HLL_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(ErosionService.erosionAddHLLSection, final);
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: HllSection }));
        yield put(
          showMessage({
            message: Erosion_Added_HLL_Section,
            type: Success,
          })
        );
        yield put(erosionSetDataSaved({ section: HllSection, value: true }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: HllSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion Human Live Lost Section

//#region LiveStock Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding LiveStock section
 */
export function* erosionAddLiveStockSection() {
  yield takeEvery(EROSION_ADD_LIVESTOCK_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddLiveStockSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: LiveStockSection }));
        yield put(
          showMessage({
            message: Erosion_Added_LiveStock_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: LiveStockSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: LiveStockSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion LiveStock Section

//#region HouseDamaged Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding HouseDamaged section
 */
export function* erosionAddHouseDamagedSection() {
  yield takeEvery(EROSION_ADD_HOUSEDAMAGED_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddHouseDamagedSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(erosionResetButtonLoading({ section: HouseDamagedSection }));
        yield put(
          showMessage({
            message: Erosion_Added_HouseDamaged_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: HouseDamagedSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: HouseDamagedSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion HouseDamaged Section

//#region RescueOperation Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding RescueOperation section
 */
export function* erosionAddRescueOperationSection() {
  yield takeEvery(EROSION_ADD_RESCUEOPERATION_SECTION, function* ({ payload }) {
    try {
      let final = {
        data: payload.data,
      };
      const erosion = yield call(
        ErosionService.erosionAddRescueOperationSection,
        final
      );
      if (erosion.error != null && erosion.error.message) {
        yield put(showMessage({ message: erosion.error.message, type: Error }));
      } else {
        yield put(
          erosionResetButtonLoading({ section: RescueOperationSection })
        );
        yield put(
          showMessage({
            message: Erosion_Added_RescueOperation_Section,
            type: Success,
          })
        );
        yield put(
          erosionSetDataSaved({ section: RescueOperationSection, value: true })
        );
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: RescueOperationSection }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}
//#endregion RescueOperation Section

//#region ReliefDistribution Section
//@author: neeraj.singh.rawat@velsof.com
//@date : 01-12-2022

/** Method for adding ReliefDistribution section
 */
export function* erosionAddReliefDistributionSection() {
  yield takeEvery(
    EROSION_ADD_RELIEFDISTRIBUTION_SECTION,
    function* ({ payload }) {
      try {
        let final = {
          data: payload.data,
        };
        const erosion = yield call(
          ErosionService.erosionAddReliefDistributionSection,
          final
        );
        if (erosion.error != null && erosion.error.message) {
          yield put(
            showMessage({ message: erosion.error.message, type: Error })
          );
        } else {
          yield put(
            erosionResetButtonLoading({ section: ReliefDistributionSection })
          );
          yield put(
            showMessage({
              message: Erosion_Added_ReliefDistribution_Section,
              type: Success,
            })
          );
          yield put(
            erosionSetDataSaved({
              section: ReliefDistributionSection,
              value: true,
            })
          );
        }
      } catch (err) {
        yield put(
          erosionResetButtonLoading({ section: ReliefDistributionSection })
        );
        yield put(
          showMessage({ message: err.response.data.error.message, type: Error })
        );
      }
    }
  );
}
//#endregion ReliefDistribution Section

//#region
/**
 * The below method is for exporting the report in erosion view form
 * @author: shivam.sharma2@velsof.com
 * @date : 12-12-2022
 */

/**
 * Method for getting the district export details
 */
export function* getExportFoLevelErosionReport() {
  yield takeEvery(EXPORT_FO_LEVEL_EROSION_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        ErosionService.getExportFoLevelErosionReport,
        payload
      );

      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        let exportData = {
          url: window.URL.createObjectURL(exportedReport),
          format: payload.format,
        };
        yield put(setExportFOLevelErosionReport(exportData));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

//#region Method for Approval and Disapproval
/**
 * @author: shivam.sharma2@velsof.com
 * @date : 10-12-2022
 */
/**
 * Method for approving the revenue report
 */
export function* erosionRevenueReportApprove() {
  yield takeEvery(EROSION_REVENUE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionRevenueReportApprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Approved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the revenue report
 */
export function* erosionRevenueReportDisapprove() {
  yield takeEvery(EROSION_REVENUE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionRevenueReportDisapprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Disapproved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * @author: shivam.sharma2@velsof.com
 * @date : 14-12-2022
 */
/**
 * Method for verify the revenue report
 */
export function* erosionRevenueReportVerify() {
  yield takeEvery(EROSION_REVENUE_REPORT_VERIFY, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionRevenueReportVerify,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Verify, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 */
export function* erosionDistrictReportApprove() {
  yield takeEvery(EROSION_DISTRICT_REPORT_APPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionDistrictReportApprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Approved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 */
export function* erosionDistrictReportDisapprove() {
  yield takeEvery(EROSION_DISTRICT_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionDistrictReportDisapprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Disapproved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: disapproveButton }));
    }
  });
}

/**
 * Method for verify the district report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 */
export function* erosionDistrictReportVerify() {
  yield takeEvery(EROSION_DISTRICT_REPORT_VERIFY, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionDistrictReportVerify,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Verify, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: verifyButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: verifyButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for approving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 */
export function* erosionStateReportApprove() {
  yield takeEvery(EROSION_STATE_REPORT_APPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionStateReportApprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Approved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: approveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: approveButton }));
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for disapproving the state report
 * @author: neeraj.singh.rawat@velsof.com
 * @date : 20-12-2022
 */
export function* erosionStateReportDisapprove() {
  yield takeEvery(EROSION_STATE_REPORT_DISAPPROVE, function* ({ payload }) {
    try {
      const erosionReportStatus = yield call(
        ErosionService.erosionStateReportDisapprove,
        payload
      );
      if (
        erosionReportStatus.error != null &&
        erosionReportStatus.error.message
      ) {
        yield put(
          showMessage({
            message: erosionReportStatus.error.message,
            type: Error,
          })
        );
      } else {
        yield put(
          showMessage({ message: Erosion_Report_Disapproved, type: Success })
        );
        yield put(erosionSetRedirectToListing(true));
        yield put(erosionResetButtonLoading({ section: disapproveButton }));
      }
    } catch (err) {
      yield put(erosionResetButtonLoading({ section: disapproveButton }));
    }
  });
}
//#endregion Method for Approval and Disapproval

//#region
/**
 * The below method is for exporting the report in erosion cumulative state report
 * @author: shivam.sharma2@velsof.com
 * @date : 15-12-2022
 */

/**
 * Method for getting the cumulative state export details
 */
export function* getExportStateCumulativeErosionReport() {
  yield takeEvery(GET_STATE_CUMULATIVE_EROSION_REPORT, function* ({ payload }) {
    try {
      const exportedReport = yield call(
        ErosionService.getExportStateCumulativeErosionReport,
        payload
      );
      if (exportedReport.error != null && exportedReport.error.message) {
        yield put(
          showMessage({ message: exportedReport.error.message, type: Error })
        );
      } else {
        if (payload?.sendEmail) {
          // Convert the Blob response to text
          const textResponse = yield exportedReport?.text();
          const parsedResponse = JSON.parse(textResponse);
          yield put(
            showMessage({
              message: parsedResponse?.message,
              type: Success,
            })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportStateCumulativeErosionReport(exportData));
        }
      }
    } catch (err) {
      let errPromise = yield err.response.data.text();
      yield put(
        showMessage({
          message: JSON.parse(errPromise).error.message,
          type: Error,
        })
      );
    }
  });
}
//#endregion

/**
 * Method for getting the cumulative district export details
 */
export function* getExportDistrictCumulativeErosionReport() {
  yield takeEvery(
    GET_DISTRICT_CUMULATIVE_EROSION_REPORT,
    function* ({ payload }) {
      try {
        const exportedReport = yield call(
          ErosionService.getExportDistrictCumulativeErosionReport,
          payload
        );
        if (exportedReport.error != null && exportedReport.error.message) {
          yield put(
            showMessage({ message: exportedReport.error.message, type: Error })
          );
        } else {
          let exportData = {
            reportData:
              payload.format === "html"
                ? exportedReport
                : window.URL.createObjectURL(exportedReport),
            format: payload.format,
          };
          yield put(setExportDistrictCumulativeErosionReport(exportData));
        }
      } catch (err) {
        let errPromise = yield err.response.data.text();
        yield put(
          showMessage({
            message: JSON.parse(errPromise).error.message,
            type: Error,
          })
        );
      }
    }
  );
}
//#endregion

/**
 * Method for getting the district review list
 */
export function* getErosionDistrictReviewList() {
  yield takeEvery(GET_EROSION_DISTRICT_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        ErosionService.getErosionDistrictReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setErosionDistrictReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for getting the state review list
 */
export function* getErosionStateReviewList() {
  yield takeEvery(GET_EROSION_STATE_REVIEW_LIST, function* ({ payload }) {
    try {
      const response = yield call(
        ErosionService.getErosionStateReviewList,
        payload
      );
      if (response.error != null && response.error.message) {
        yield put(
          showMessage({
            message: response.error.message,
            type: Error,
          })
        );
      } else {
        yield put(setErosionStateReviewList(response));
      }
    } catch (err) {
      yield put(
        showMessage({ message: err.response.data.error.message, type: Error })
      );
    }
  });
}

/**
 * Method for Freezing and unfreezing erosion report for a selected date range
 * @author: vishal.mishra@velsof.com
 * @date : 22-01-2024
 */
export function* erosionFreezeReportRange() {
  yield takeEvery(
    FREEZE_UNFREEZE_EROSION_RANGE_REPORT,
    function* ({ payload }) {
      try {
        const erosionReportStatus = yield call(
          ErosionService.erosionFreezeReportRange,
          payload
        );
        if (
          erosionReportStatus.error != null &&
          erosionReportStatus.error.message
        ) {
          yield put(
            showMessage({
              message: erosionReportStatus.error.message,
              type: Error,
            })
          );
        } else {
          yield put(
            showMessage({ message: Erosion_Freeze_Successfully, type: Success })
          );
          yield put(erosionResetButtonLoading({ section: freezeButton }));
        }
      } catch (err) {
        yield put(erosionResetButtonLoading({ section: freezeButton }));
      }
    }
  );
}
//#endregion Method for Approval and Disapproval

/**
 * Method for storing the multiple Sagas method
 */
export default function* rootSaga() {
  yield all([
    fork(getAllErosion),
    fork(getErosionExport),
    fork(erosionImport),
    fork(erosionAddBasicSection),
    fork(erosionEditBasicSection),
    fork(erosionGetAllSection),
    fork(erosionAddInfDamageSection),
    fork(erosionAddInfDamageOtherSection),
    fork(erosionAddRemarkSection),
    fork(erosionReportSubmit),
    fork(erosionReportDiscard),
    fork(erosionAddPopAffectedSection),
    fork(erosionAddErodedSection),
    fork(erosionAddReliefCampSection),
    fork(erosionAddCampInmatesSection),
    fork(erosionAddNonCampInmatesSection),
    fork(erosionAddHLLSection),
    fork(erosionAddLiveStockSection),
    fork(erosionAddHouseDamagedSection),
    fork(erosionAddRescueOperationSection),
    fork(erosionAddReliefDistributionSection),
    fork(erosionRevenueReportApprove),
    fork(erosionRevenueReportDisapprove),
    fork(erosionRevenueReportVerify),
    fork(erosionDistrictReportApprove),
    fork(erosionDistrictReportDisapprove),
    fork(erosionDistrictReportVerify),
    fork(erosionStateReportApprove),
    fork(erosionStateReportDisapprove),
    fork(getExportFoLevelErosionReport),
    fork(getErosionDistrictReviewList),
    fork(getErosionStateReviewList),
    fork(getExportStateCumulativeErosionReport),
    fork(getExportDistrictCumulativeErosionReport),
    fork(erosionFreezeReportRange),
  ]);
}
