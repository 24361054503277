import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_CAMP_INMATES_DATA,
  GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
  SET_ALL_CREATE_EROSION_INMATES_REGISTRATION,
  SET_ALL_EROSION_INMATES_REGISTRATION,
  SET_ALL_UPDATE_EROSION_INMATES_REGISTRATION,
  SET_EXPORT_CAMP_INMATES_DATA,
  SET_EROSION_INMATES_REGISTRATION,
  SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA,
} from "redux/constants/ErosionInmatesRegistration";

// Define an initial state
const initialState = {
  erosionInmatesRegistrationData: [],
  erosionIsInmatesRegistrationDataFetched: false,
  erosionInmatesRegistrationListingParam: {},
  erosionCampInmatesRegistrationExportedData: {},
  erosionCampInmatesRegistrationImportTemplateData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const erosionInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_EROSION_INMATES_REGISTRATION:
      return {
        ...state,
        erosionInmatesRegistrationData: action.payload,
        erosionIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_EROSION_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        erosionInmatesRegistrationData: action.payload,
        erosionIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_EROSION_INMATES_REGISTRATION:
      return {
        ...state,
        erosionInmatesRegistrationData: action.payload,
        erosionIsInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_EROSION_INMATES_REGISTRATION:
      return {
        ...state,
        erosionInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_CAMP_INMATES_DATA:
      return {
        ...state,
        erosionCampInmatesRegistrationExportedData: action.payload,
      };

    case GET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        payload: action.payload,
      };

    case SET_IMPORT_TEMPLATE_CAMP_INMATES_DATA:
      return {
        ...state,
        erosionCampInmatesRegistrationImportTemplateData: action.payload,
      };

    default:
      return state;
  }
};

export default erosionInmatesRegistration;
