import { AUTH_TOKEN } from "redux/constants/Auth";
import {
  GET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION,
  SET_ALL_EROSION_NON_INMATES_REGISTRATION,
  SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION,
  SET_EXPORT_NON_CAMP_INMATES_DATA,
  SET_EROSION_NON_INMATES_REGISTRATION,
} from "redux/constants/ErosionNonInmatesRegistration";

// Define an initial state
const initialState = {
  erosionNonInmatesRegistrationData: [],
  erosionIsNonInmatesRegistrationDataFetched: false,
  erosionNonInmatesRegistrationListingParam: {},
  erosionNonCampInmatesRegistrationExportedData: {},
  token: localStorage.getItem(AUTH_TOKEN),
};

// Reducer function
const erosionNonInmatesRegistration = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_EROSION_NON_INMATES_REGISTRATION:
      return {
        ...state,
        erosionNonInmatesRegistrationData: action.payload,
        erosionIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_CREATE_EROSION_NON_INMATES_REGISTRATION:
      // Handle the logic to create a new report here
      // You can update the state accordingly
      // For example, if you want to store the new report data, you can do something like this:

      return {
        ...state,
        erosionNonInmatesRegistrationData: action.payload,
        erosionIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_ALL_UPDATE_EROSION_NON_INMATES_REGISTRATION:
      return {
        ...state,
        erosionNonInmatesRegistrationData: action.payload,
        erosionIsNonInmatesRegistrationDataFetched:
          Object.keys(action.payload).length > 0 ? true : false,
      };

    case SET_EROSION_NON_INMATES_REGISTRATION:
      return {
        ...state,
        erosionNonInmatesRegistrationListingParam: action.payload,
      };

    case GET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
      };

    case SET_EXPORT_NON_CAMP_INMATES_DATA:
      return {
        ...state,
        erosionNonCampInmatesRegistrationExportedData: action.payload,
      };

    default:
      return state;
  }
};

export default erosionNonInmatesRegistration;
